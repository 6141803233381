* {
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
  background-color: #eef5f6;
}

html {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
